import React, { lazy, Suspense } from 'react'
// import { Switch, Route } from 'react-router'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Header from './Header/Header'
import Nav from './Nav/Nav'

const Andiamo = lazy(() => import('./Andiamo/Andiamo'));
const Chart = lazy(() => import('./Chart/Chart'));
const LeaderBoard = lazy(() => import('./LeaderBoard/LeaderBoard'));
const Info = lazy(() => import('./Info/Info'));
const Sitemap = lazy(() => import('./Sitemap/Sitemap'));
const Privacy = lazy(() => import('./Privacy/Privacy'));
const InfoDetails = lazy(() => import('./InfoDetails/InfoDetails'));

const App = () => (
  <>
    <Header />
    <BrowserRouter>
      <Nav />
      <Suspense fallback={<div></div>}>
      {/* <Suspense fallback={<div><img src="spinner.gif" alt="Loading"/></div>}> */}
      {/* <Suspense> */}
        <Switch>
            <Route path="/andiamo" component={Andiamo} />
            <Route path="/leaderboard" component={LeaderBoard} />
            <Route exact path="/info/:url" component={InfoDetails} />
            <Route exact path="/info" component={Info} />
            <Route exact path="/sitemap" component={Sitemap} />
            <Route exact path="/privacy" component={Privacy} />
            <Route path="/" component={Chart} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </>
)

export default App;
