import React from 'react'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import styles from './Nav.module.css'

const Nav = () => {

  const dispatch = useDispatch()
  const { name } = useSelector(({ name }) => ({ name }))
  const { isLoggedIn } = useSelector(({ isLoggedIn }) => ({ isLoggedIn }))
  console.log('Nave isLoggedIn: ', isLoggedIn);
  console.log('name: ', name);

  const responseFacebook = (response) => {
    console.log(response);
    if (response.accessToken) {
      dispatch({type: 'SET_IS_LOGGED_IN', payload: true})
      dispatch({type: 'SET_NAME', payload: response.name})
    }
  }

  const handleLogout = () => {
    window.FB.logout((response) => {
      console.log('logout response: ', response);
      if (response.status !== 'connected') {
        dispatch({type: 'SET_IS_LOGGED_IN', payload: false})
      }
    })
  }

  return (
    <>
      <nav className={styles.nav}>
        <Link to='/chart'>Chart</Link>
        <Link to='/leaderboard'>Leaderboard</Link>
        <Link to='/info'>Info</Link>
        {!isLoggedIn && <FacebookLogin
          isMobile={false}
          appId="601917013782023"
          // autoLoad={true}
          fields="name,email,picture"
          // onClick={componentClicked}
          callback={responseFacebook}
          render={renderProps => (
            <button className={styles.loginButton} onClick={renderProps.onClick}>Login with Facebook</button>
          )}
        />}
        {isLoggedIn &&
          <button className={styles.loginButton}>
            {name || 'Log out'}
            <div className={styles.logoutButton} onClick={() => handleLogout()}>Logout</div>
          </button>}
      </nav>
    </>
  )
}

export default Nav
