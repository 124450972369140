const initialState = {
  isLoggedIn: false,
  name: '',
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NAME':
      return {
        ...state,
          name: action.payload,
      }
    case 'SET_IS_LOGGED_IN':
      return {
        ...state,
          isLoggedIn: action.payload,
      }
    default:
      return state
  }
}

export default rootReducer
