import React from 'react'
import logo from './stockChart.jpg'
import styles from './Header.module.css'

const Header = () => (
  <div className={styles.header}>
    <a className={styles.link} href="/">
        <img className={styles.logo} src={logo} alt="stock chart"/>
        <h1>Stock Chart Guru</h1>
    </a>
  </div>
)

export default Header
